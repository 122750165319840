import React, { Component } from 'react';
import { Index } from 'elasticlunr';
import { Link } from 'gatsby';
import './search.less';
import getRecipeSlug from '../utils/getRecipeSlug';

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ``,
      results: [],
    };
  }


  render() {
    console.log('results', this.state.results);

    return (
      <div id="searchpage">
        <p>
          Szukaj przepisów wpisując słowa kluczowe w pole poniżej. Możesz szukać
          przepisów po nazwie, składniku, tagach. Kliknij na tytuł, aby przejść
          do przepisu.
        </p>
        <input
          type="text"
          value={this.state.query}
          onChange={this.search}
          placeholder="Podaj nazwę, składnik, tag..."
        />
        <ul>
          {this.state.results.map((page) => (
            <li key={page.id}>
              <Link className="recipe-name-link" to={getRecipeSlug(page.path)}>
                <h2>{page.name}</h2>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  search = (evt) => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    console.log('index', this.index)
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };
}
