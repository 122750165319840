import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/menu';
import { graphql, StaticQuery } from 'gatsby';
import Search from '../components/search';

const SzukajPage = () => {

  return (
    <Layout>
      <SEO title="Szukaj przepisów" />
      <header>
        <Menu></Menu>
      </header>
      <section className="page-content">
        <h1 className="section-header">Szukaj przepisów</h1>
        <StaticQuery
          query={graphql`
            query SearchIndexQuery {
              siteSearchIndex {
                index
              }
            }
          `}
          render={data => (
              <Search searchIndex={data.siteSearchIndex.index} />
          )}
        />
      </section>
    </Layout>
  );
};

export default SzukajPage;
